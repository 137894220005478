import { HttpPost } from '@/utils/request'

// 案件列表
export async function casesListApi (options) {
  return await HttpPost('/cases/index', options)
}

// 添加案件
export async function addCasesApi (options) {
  return await HttpPost('/cases/addCases', options)
}

// 编辑案件
export async function editCasesApi (options) {
  return await HttpPost('/cases/editCases', options)
}

// 案件详情
export async function detailCasesApi (options) {
  return await HttpPost('/cases/detail', options)
}

// 检阅案件
export async function isCheckApi (options) {
  return await HttpPost('/cases/isCheck', options)
}
