<template>
  <div class="container">
    <div>
      <div class="search-container">
        <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
        >
          <el-form-item label="当事人">
            <el-input v-model="formInline.publisher" placeholder="请输入当事人名称"></el-input>
          </el-form-item>
          <el-form-item label="案件描述">
            <el-input v-model="formInline.describe" placeholder="请输入案件描述"></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input v-model="formInline.mobile" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="案件类型">
            <el-select v-model="formInline.cases_type_id" placeholder="请选择案件类型">
              <el-option
                  v-for="item of casesType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否检阅">
            <el-select v-model="formInline.is_check" placeholder="请选择是否检阅">
              <el-option
                  v-for="item of is_checkList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="省份">
            <el-input v-model="formInline.province" placeholder="请输入省份"></el-input>
          </el-form-item>
          <el-form-item label="城市">
            <el-input v-model="formInline.city" placeholder="请输入城市"></el-input>
          </el-form-item>
          <el-form-item label="县区">
            <el-input v-model="formInline.county" placeholder="请输入县区"></el-input>
          </el-form-item>
          <el-form-item label="发布时间">
            <el-date-picker
                v-model="date"
                value-format="yyyy-MM-dd"
                type="daterange"
                @change="dateHandle"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSearch">查询</el-button>
            <el-button v-indentify="'cases:list:view:addCases'" type="primary" icon="el-icon-plus" @click="handle('add')">添加案件</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table-container">
        <el-table
            :data="tableData"
            border
            style="width: 100%"
        >
          <el-table-column
              prop="cases_id"
              width="80"
              label="案件ID">
          </el-table-column>
          <el-table-column
              prop="publisher"
              width="100"
              label="当事人">
          </el-table-column>
          <el-table-column
              prop="cases_type_name"
              width="100"
              label="案件类型">
          </el-table-column>
          <el-table-column
              prop="amount"
              width="110"
              label="案件涉及金额">
          </el-table-column>
          <el-table-column
              label="案件描述">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.describe">
                <div slot="reference" class="ellipsis">{{ scope.row.describe }}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
              label="备注">
            <template slot-scope="scope">
              <el-popover
                  placement="top-start"
                  width="200"
                  trigger="hover"
                  :content="scope.row.remarks">
                <div slot="reference" class="ellipsis">{{ scope.row.remarks }}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
              prop="area"
              width="200"
              label="地区">
          </el-table-column>
          <el-table-column
              width="90"
              align="center"
              label="是否检阅">
            <template slot-scope="scope">
              <StatusTag :state="scope.row.is_check" :data="is_checkEnum"/>
            </template>
          </el-table-column>
          <el-table-column
              prop="publish_time"
              width="200"
              label="创建时间">
            <template slot-scope="scope"><span>{{ scope.row.create_time | dateFormat }}</span></template>
          </el-table-column>
          <el-table-column
              width="120"
              align="center"
              label="操作">
            <template slot-scope="scope">
              <template v-if="scope.row.is_check === 2">
                <el-button v-indentify="'cases:list:view:editCases'" type="primary" icon="el-icon-edit" size="mini"
                           @click="handle('edit', scope.row.cases_id)">编辑
                </el-button>
              </template>

            </template>
          </el-table-column>
        </el-table>
      </div>
      <PcPaginationView @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :total="total"
                        :page-size="page_size"/>
    </div>
    <CasesDrawer :visible.sync="showDrawer" :selectedArea="selectedArea" :title="handleFlag === 'add' ? '添加案件' : '编辑案件'"
                 :drawerForm="drawerForm" :flag="handleFlag" @areaChange="areaChange" @cancel="cancelDrawer" @confirm="confirmDrawer"></CasesDrawer>
  </div>
</template>

<script>
import Mixin from '@/utils/Mixin'
import { mapState, mapActions } from 'vuex'
import { setSelectedArea } from '@/utils/tools'
import { casesListApi, detailCasesApi } from '@/api/cases'
import StatusTag from '@/components/pc/common/StatusTag'
import CasesDrawer from '@/components/pc/CasesDrawer'
import PcPaginationView from '@/components/Pagination/pc'

export default {
  name: 'PcCasesList',
  mixins: [Mixin],
  components: {
    StatusTag,
    CasesDrawer,
    PcPaginationView
  },
  computed: {
    ...mapState('selectOptions', ['is_checkList', 'casesType']),
    ...mapState('statusTagEnum', ['is_checkEnum'])
  },
  data () {
    return {
      selectedArea: [],
      formInline: {
        publisher: '',
        cases_type_id: '',
        mobile: '',
        is_check: '',
        province: '',
        city: '',
        county: '',
        start_time: '',
        end_time: ''
      },
      date: [],
      page: 1,
      page_size: 10,
      total: 0,
      tableData: [],
      drawerForm: {
        cases_id: '',
        publisher: '',
        province: '',
        city: '',
        county: '',
        cases_type_id: '',
        amount: '',
        describe: '',
        is_check: 2,
        mobile: '',
        remarks: ''
      },
      cases_id: '',
      handleFlag: '',
      showDrawer: false

    }
  },
  mounted () {
    this.getList()
    this.getCasesType()
  },
  methods: {
    ...mapActions('selectOptions', ['getCasesType']),
    async getList () {
      const data = await casesListApi({
        page: this.page,
        page_size: this.page_size,
        ...this.formInline
      })
      if (data.code === 1) {
        this.tableData = data.data.data
        this.total = data.data.total
      }
    }, // 查询
    onSearch () {
      this.page = 1
      this.getList()
    },
    async handle (flag, cases_id = '') {
      if (flag === 'edit') {
        this.drawerForm.cases_id = cases_id
        await this.getDetail()
      }
      this.showDrawer = true
      this.handleFlag = flag
    },
    async getDetail () {
      const { cases_id } = this.drawerForm
      const data = await detailCasesApi({ cases_id })
      if (data.code === 1) {
        if (data.data === null) return
        const detail = data.data
        const { publisher, province, city, county, cases_type_id, amount, describe, is_check, mobile, remarks } = detail
        this.drawerForm = {
          cases_id,
          publisher,
          province,
          city,
          county,
          cases_type_id,
          amount,
          describe,
          is_check,
          mobile,
          remarks
        }
        this.selectedArea = this.setSelectedArea(detail)
      }
    },
    /**
     * 修改data数组某个下标的值
     * @param cases_id
     * @param listKey
     * @param value
     */
    modifyList (cases_id, listKey, value) {
      const { tableData } = this.$data
      const index = tableData.findIndex(item => item.cases_id === cases_id)
      this.$set(this.tableData[index], listKey, value)
    },
    setSelectedArea,
    handleSizeChange (val) {
      this.page_size = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.page = val
      this.getList()
    },
    areaChange (value) {
      this.drawerForm.province = value[0] ?? ''
      this.drawerForm.city = value[1] ?? ''
      this.drawerForm.county = value[2] ?? ''
    },
    // 关闭抽屉
    cancelDrawer () {
      const { drawerForm } = this.$data
      for (const drawerFormKey in drawerForm) {
        if (drawerFormKey === 'is_check') {
          drawerForm[drawerFormKey] = 2
          continue
        }
        drawerForm[drawerFormKey] = ''
      }
      this.drawerForm = drawerForm
      this.selectedArea = []
    },
    confirmDrawer () {
      this.getList()
    },
    /**
     * 时间区间change
     * @param date
     */
    dateHandle (date) {
      if (date) {
        this.formInline.start_time = date[0]
        this.formInline.end_time = date[1]
      } else {
        this.formInline.start_time = ''
        this.formInline.end_time = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
