<template>
  <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="show"
      custom-class="drawer"
      ref="drawer"
      size="50%"
  >
    <div class="demo-drawer__content">
      <el-form :model="drawerForm" label-width="100px">
        <el-form-item label="案件id" v-if="drawerForm.cases_id">
          <el-input disabled v-model="drawerForm.cases_id" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="当事人名称">
          <el-input v-model="drawerForm.publisher"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-cascader
              style="width: 100%"
              ref="cascader"
              placeholder="选择地址"
              v-model="areaValue"
              :options="area"
              :props="{label: 'name', value: 'id', checkStrictly: true}"></el-cascader>
        </el-form-item>
        <el-form-item label="涉案金额">
          <el-input v-model="drawerForm.amount" type="number" />
        </el-form-item>
        <el-form-item label="案件类型">
          <el-select style="width: 100%" v-model="drawerForm.cases_type_id" placeholder="请选择案件类型">
            <el-option
                v-for="item of casesType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否检阅">
          <el-switch
              v-model="drawerForm.is_check"
              :active-value="1"
              :inactive-value="2"
              active-text="已检阅"
              inactive-text="未检阅">
          </el-switch>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="drawerForm.mobile" />
        </el-form-item>
        <el-form-item label="案件描述">
          <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="drawerForm.describe" ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="drawerForm.remarks" ></el-input>
        </el-form-item>
        <slot></slot>
      </el-form>
      <div class="demo-drawer__footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button v-indentify="'order:list:view:setServiceTime'" type="primary" @click="submit" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { addCasesApi, editCasesApi } from '@/api/cases'

export default {
  name: 'pcCasesDrawerComponents',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    flag: {
      type: String,
      default: ''
    },
    drawerForm: {
      type: Object,
      default: () => ({})
    },
    selectedArea: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    ...mapState('selectOptions', ['serviceItem', 'casesType']),
    ...mapState('config', ['area']),
    show: {
      get () {
        return this.$props.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    areaValue: {
      get () {
        return this.$props.selectedArea
      },
      set (val) {
        this.areaChange(val)
      }
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    // 关闭
    cancelForm () {
      this.loading = false
      this.show = false
      this.$emit('cancel')
    },
    // 关闭前
    async handleClose (done) {
      if (this.loading) {
        this.$message.info('正在提交，请稍后再关闭！')
        return
      }
      await this.$confirm('确定关闭吗？')
      this.cancelForm()
    },
    async submit () {
      const { drawerForm, flag } = this.$props
      let data = null
      let msg = ''
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        if (flag === 'add') {
          data = await addCasesApi(drawerForm)
          msg = '添加'
        } else {
          data = await editCasesApi(drawerForm)
          msg = '编辑'
        }
        if (data.code === 1) {
          this.cancelForm()
          this.$message.success(msg + '案件成功！！！')
          this.$emit('confirm')
        }
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 选择地址
    areaChange (value) {
      let areaValue = []
      if (value.length > 0) {
        // 获取当前选中节点
        const checkNode = this.$refs.cascader.getCheckedNodes()[0]
        const { pathLabels } = checkNode
        areaValue = pathLabels
      } else {
        areaValue = ['', '', '']
      }
      this.$emit('areaChange', areaValue)
    }
  },
  watch: {
    drawerForm: {
      handler (newValue, oldValue) {
        this.drawerForm = newValue
      },
      deep: true
    }

  }
}
</script>

<style scoped lang="scss">
.demo-drawer__content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  form {
    flex: 1;
  }
  .demo-drawer__footer {
    display: flex;
    button {
      flex: 1;
    }
  }
}
</style>
